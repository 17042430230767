<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col cols="12" md="6">
                    <ValidationProvider name="name" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('name')">
                            <b-form-input v-model="formData.name" :state="errors[0] ? false : null"/>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="6">
                    <b-form-group :label="$t('giveable_roles')">
                        <multi-selectbox
                            v-model="formData.giveable_roles" :options="roleOptions"
                        ></multi-selectbox>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                    <b-form-group :label="$t('explanation')">
                        <ValidationProvider name="explanation" rules="required" v-slot="{valid, errors}">
                            <b-input-group class="mb-1" prepend="TR">
                                <b-form-input v-model="formData.explanation" :state="errors[0] ? false : null"/>
                            </b-input-group>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2"/>
                        </ValidationProvider>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                    <b-form-group :label="$t('explanation_en')">
                        <ValidationProvider  name="explanation_en" rules="required" v-slot="{valid, errors}">
                            <b-input-group prepend="EN">
                                <b-form-input v-model="formData.explanation_en" :state="errors[0] ? false : null"/>
                            </b-input-group>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </ValidationProvider>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                    <b-form-group :label="$t('two_factor_authentication')">
                        <ValidationProvider name="two_factor" rules="required" v-slot="{valid, errors}">
                            <status-selectbox
                                v-model="formData.two_factor"
                                :valueType="'true'"
                                :validateError="errors[0]"
                            />
                        </ValidationProvider>
                    </b-form-group>
                </b-col>
            </b-row>
            <div class="d-flex justify-content-center mt-5">
                <b-button variant="primary" @click="updateForm" :disabled="formLoading">
                    {{ $t('save') }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
import MultiSelectbox from '../../../components/interactive-fields/MultiSelectbox'
import CheckboxWithFilter from '@/components/elements/CheckboxWithFilter'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import RoleService from '@/services/RoleService'
import StatusSelectbox from "@/components/interactive-fields/StatusSelectbox.vue";

export default {
    components: {
        StatusSelectbox,
        CheckboxWithFilter,
        ValidationProvider,
        ValidationObserver,
        MultiSelectbox
    },
    props: {
        formId: {
            type: Number
        }
    },
    data() {
        return {
            formLoading: false,
            formData: {
                name: null,
                explanation: null,
                explanation_en: null,
                giveable_roles: []
            },
            roleOptions: []
        }
    },
    methods: {
        get() {
            RoleService.get(this.formId)
                .then(response => {
                    let data = response.data.data
                    this.formData = {
                        name: data.name,
                        explanation: data.explanation,
                        explanation_en: data.explanation_en,
                        giveable_roles: data.giveable_roles,
                        two_factor:data.two_factor==true?1:0
                    }
                })
                .catch(e => {
                    this.showErrors(e)
                })
        },
        async updateForm() {
            const isValid = await this.$refs.formModalValidate.validate()

            if (isValid && this.formId) {
                this.formLoading = true

                RoleService.update(this.formId, this.formData)
                    .then(response => {
                        this.$emit('updateFormSuccess', true)
                        this.$toast.success(this.$t('api.' + response.data.message))
                    })
                    .catch(e => {
                        this.showErrors(e, this.$refs.formModalValidate)
                    })
                    .finally(() => {
                        this.formLoading = false
                    })
            }
        },
        getRoles() {
            this.roleOptions = []

            const config = {
                params: {
                    limit: -1
                }
            }

            RoleService.getAll(config)
                .then((response) => {
                    this.roleOptions = response?.data?.data?.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
                        ?.map((item) => {
                            return {
                                value: item.name,
                                text: this.getLocaleText(item, 'explanation')
                            }
                        }) ?? []

                })
                .catch((e) => {
                    this.showErrors(e)
                })
        }
    },
    async created() {
        await this.get()
        this.getRoles()
    }

}
</script>
