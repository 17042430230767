<template>
    <div>
        <b-form-group label-cols="4" label-cols-lg="2" label="Rol Ekle" label-for="input-default"></b-form-group>
        <b-row>
            <b-col cols="12" md="6" lg="6">
                <b-form-group :label="$t('name')">
                    <div class="label-as-input">{{ isNotNullValue(formData.name) }}</div>
                </b-form-group>
            </b-col>
            <b-col cols="12" md="6" lg="12">
                <b-form-group :label="$t('explanation')">
                    <b-input-group prepend="TR">
                        <div class="label-as-input">{{ isNotNullValue(formData.explanation) }}</div>
                    </b-input-group>
                    <b-input-group prepend="EN">
                        <div class="label-as-input">{{ isNotNullValue(formData.explanation_en) }}</div>
                    </b-input-group>
                </b-form-group>
            </b-col>
        </b-row>
        <b-form-group label-cols="4" label-cols-lg="2" label="Permission Ekle" label-for="input-default"></b-form-group>
        <b-col cols="12" md="4" lg="12">
            <div class="label-as-input">{{ isNotNullValue(formDataPerm.permissions) }}</div>
        </b-col>
    </div>
</template>

<script>
    // Services
    import PermissionService from '@/services/PermissionService';
    import RoleService from '@/services/RoleService';

    export default {
        components: {},
        props: {
            formId: {
                type: Number,
            }
        },
        data() {
            return {
                permissionss: [],
                formLoading: false,
                formDataPerm: {
                    permissions: []
                },
                formData: {
                    name: null,
                    explanation: null,
                    explanation_en: null
                }
            }
        },
        methods: {
            get() {
                RoleService.get(this.formId)
                           .then(response => {
                               let data = response.data.data;
                               this.formData = {
                                   name: data.name,
                                   explanation: data.explanation,
                                   explanation_en: data.explanation_en
                               }
                           })
                           .catch(e => {
                               this.showErrors(e)
                           })
            },
            async getRolePermissions() {
                await PermissionService.get(this.formId)
                                       .then(response => {
                                           let permissions = response.data.data.permissions;
                                           this.formDataPerm.permissions = permissions.map(item => {
                                               return item.name;
                                           })
                                       })
                                       .catch(e => {
                                           this.showErrors(e);
                                       })
            }

        },
        async created() {
            await this.get()
            await this.getRolePermissions()
        }

    }
</script>
